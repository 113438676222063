.content-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.app-title {
  font-weight: bold;
}

.divider {
  color: #f57c00;
}

.loading-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ant-select {
  width: 100% !important;
}

body {
  background-color: #fafafa;
}

.table-card {
  width: 98%;
}

.custom-menu {
  background-color: transparent !important;
  color: white !important;
}

.ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: #f57b0053;
}

.ant-table-body {
  overflow-y: scroll !important;
}

/* Styling the custom menu */
.custom-menu {
  background-color: transparent !important;
  color: white !important;
}

/* Overriding the hover state for the menu icon */
.custom-menu.ant-menu-horizontal
  > .ant-menu-submenu
  > .ant-menu-submenu-title:hover
  .anticon,
.custom-menu.ant-menu-horizontal
  > .ant-menu-item
  > .ant-menu-item-content:hover
  .anticon {
  color: white !important;
}

/* Overriding the selected state for the menu icon */
.custom-menu.ant-menu-horizontal
  > .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  .anticon {
  color: white !important;
}
