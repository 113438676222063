.logo img {
  height: 120px;
  margin-top: 40px;
}

.sennder-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f57c00 !important;
}
