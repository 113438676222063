.content {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: url("../../assets/images/intro.jpg") no-repeat center center;
  background-size: cover;
}

.block {
  background-color: rgba(#fff, 0.95);
  padding: 48px 24px;
  border-radius: 4px;
  width: 400px;
}

img {
  height: 60px;
  margin-bottom: 20px;
}

.text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
