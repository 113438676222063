.info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 70%;
  margin: 2%;
}

.info-wrapper > h1 {
  color: #f57c00;
  font-weight: bold;
}
